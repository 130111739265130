import React, { MutableRefObject } from 'react';
import styled from 'styled-components';
import { Translate, t } from 'i18n';
import CircleButton from '../CircleButton';
import { ICircleButton } from '../CircleButton/CircleButton';

interface IProps extends ICircleButton {
  innerTextRef?: MutableRefObject<HTMLSpanElement | null>;
}

const StartButton = React.forwardRef<HTMLButtonElement | null, IProps>(
  ({ innerTextRef, ...props }, forwardedRef) => {
    return (
      <SStartButton
        ref={forwardedRef}
        {...props}
        title={t('frontend.lesson_task.start_button')}
      >
        <SButtonText ref={innerTextRef}>
          <Translate str="frontend.lesson_task.start_button" />
        </SButtonText>
      </SStartButton>
    );
  }
);

const SStartButton = styled(CircleButton)`
  &:hover,
  &:focus {
    background-color: var(--lingu-light-btn-bg-hover);
    border-color: var(--lingu-light-btn-bg-hover);
  }
  &:active {
    background-color: var(--lingu-light-btn-bg-pressed);
    border-color: var(--lingu-light-btn-bg-pressed);
  }
`;

const SButtonText = styled.span`
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default StartButton;
