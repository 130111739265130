import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { customMediaQuery } from '../../styled';
import ButtonGeneral from '../ButtonGeneral';
import { tSpinnerVariant } from '../Spinner/Spinner';
import UrlIcon from '../UrlIcon';

export interface ICircle {
  color?: string;
  size?: string;
  bgColor?: string;
  shadowColor?: string;
  disabled?: boolean;
  title?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export interface ICircleButton extends ICircle {
  children?: React.ReactElement | React.ReactElement[];
  iconUrl?: string;
  iconSize?: string;
  iconColor?: string;
  showSpinner?: boolean;
  spinnerVariant?: tSpinnerVariant;
}

const CircleButton = React.forwardRef<HTMLButtonElement | null, ICircleButton>(
  (
    {
      children,
      color,
      size,
      shadowColor,
      onClick,
      disabled,
      bgColor,
      iconUrl,
      iconColor,
      className,
      title,
      showSpinner,
      spinnerVariant
    },
    forwardedRef
  ) => (
    <SCircleButton
      size={size}
      shadowColor={shadowColor}
      color={color}
      onClick={onClick}
      bgColor={bgColor}
      disabled={disabled}
      className={cn(className)}
      title={title}
      showSpinner={showSpinner}
      spinnerVariant={spinnerVariant}
      ref={forwardedRef}
    >
      {iconUrl && (
        <SIconWrapper>
          <UrlIcon
            url={iconUrl}
            color={iconColor || '#2D2D3A'}
            width="1em"
            height="1em"
          />
        </SIconWrapper>
      )}
      {children}
    </SCircleButton>
  )
);

export const SCircleButton = styled(ButtonGeneral)<ICircle>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  border-radius: 100%;
  box-shadow: 0 4px 0 ${({ shadowColor }) => shadowColor || 'rgba(0, 0, 0, 0.2)'};
  border: none;
  width: ${({ size }) => size || '6rem'};
  height: ${({ size }) => size || '6rem'};
  color: ${({ color }) => color || '#270736'};
  background-color: ${({ bgColor }) => bgColor || 'var(--color-white)'};
  font-family: var(--lingu-text-font-family);
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2.1rem;
  text-transform: uppercase;
  &:hover,
  &:focus,
  &:active {
    color: ${({ color }) => color || '#262626'};
  }

  &:focus {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 3px;
  }

  ${customMediaQuery('tablet')} {
    width: ${({ size }) => size || '8.125rem'};
    height: ${({ size }) => size || '8.125rem'};
    font-size: 1.5rem;
  }
`;

export const SIconWrapper = styled.div`
  width: 100%;
`;

export default CircleButton;
