import { useContext, useCallback } from 'react';

import PromptContext from '../providers/Prompt/PromptContext';

interface IReturnedValue {
  isDirty: boolean;
  makeDirty: (dirty: boolean) => void;
}

function usePromptContext(): IReturnedValue {
  const { isDirty, makeDirty: _makeDirty } = useContext(PromptContext);

  const makeDirty = useCallback(
    (newDirty: boolean) => {
      if (isDirty === newDirty) {
        return;
      }

      _makeDirty(newDirty);
    },
    [_makeDirty, isDirty]
  );

  return {
    isDirty,
    makeDirty
  };
}

export default usePromptContext;
