import React, { ReactElement } from 'react';

import { BubbleChanger } from '../components/BubbleChanger';

interface IButtonPosition {
  left: number;
  top: number;
  width: number;
  height: number;
}

interface ICustomWindow extends Window {
  linguBubbleStartButtonPosition?: IButtonPosition;
  linguBubbleFinishButtonPosition?: IButtonPosition;
}

declare let window: ICustomWindow;

const bubbleAnimationManager = {
  get startButtonPosition(): IButtonPosition | undefined {
    return window.linguBubbleStartButtonPosition;
  },

  set startButtonPosition(position: IButtonPosition | undefined) {
    window.linguBubbleStartButtonPosition = position;
    window.dispatchEvent(new Event('startButtonPositioned'));
  },

  get finishButtonPosition(): IButtonPosition | undefined {
    return window.linguBubbleFinishButtonPosition;
  },

  set finishButtonPosition(position: IButtonPosition | undefined) {
    window.linguBubbleFinishButtonPosition = position;
  },

  get isFinishButtonDetected(): boolean {
    return !!window.linguBubbleFinishButtonPosition;
  },

  get showBubbleAnimation(): boolean {
    return !!window.linguBubbleFinishButtonPosition;
  },

  onStartButtonPositioned(callback: () => void): void {
    window.addEventListener('startButtonPositioned', callback);
  },

  offStartButtonPositioned(callback: () => void): void {
    window.removeEventListener('startButtonPositioned', callback);
  },

  clearAnimationData(): void {
    window.linguBubbleStartButtonPosition = undefined;
    window.linguBubbleFinishButtonPosition = undefined;
  },

  get growingBubbleChanger(): ReactElement | null {
    return this.finishButtonPosition ? (
      <BubbleChanger
        type="grow"
        left={`${this.finishButtonPosition.left}px`}
        top={`${this.finishButtonPosition.top}px`}
        width={`${this.finishButtonPosition.width}px`}
        height={`${this.finishButtonPosition.height}px`}
      />
    ) : null;
  },

  get shrinkingBubbleChanger(): ReactElement | null {
    return this.startButtonPosition ? (
      <BubbleChanger
        type="shrink"
        left={`${this.startButtonPosition.left}px`}
        top={`${this.startButtonPosition.top}px`}
        width={`${this.startButtonPosition.width}px`}
        height={`${this.startButtonPosition.height}px`}
      />
    ) : null;
  },

  get loadingBubbleChanger(): ReactElement | null {
    return this.finishButtonPosition ? (
      <BubbleChanger
        type="loading"
        left={`${this.finishButtonPosition.left}px`}
        top={`${this.finishButtonPosition.top}px`}
        width={`${this.finishButtonPosition.width}px`}
        height={`${this.finishButtonPosition.height}px`}
      />
    ) : null;
  }
};

export default bubbleAnimationManager;
